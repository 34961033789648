import React, { ChangeEvent, useState, useEffect } from "react"
import { appendScript } from "assets/helper/appendScript"
import { FaUser, FaLock } from "react-icons/fa"
import "./login.module.scss"
import { ExternalLink } from "components/external-link/ExternalLink"
import { useGeneralSettings } from "hooks/useGeneralSettings"

interface LoginFormProps {
  heading?: string
  subHeading?: string
}

export const LoginForm = ({ heading, subHeading }: LoginFormProps) => {
  const [user, setUser] = useState("")
  const [password, setPassword] = useState("")
  const [subDomain, setSubDomain] = useState("")
  const [isRender, setIsRender] = useState(false)
  const { companyName } = useGeneralSettings()
  const loginMessage = "Your Username / Password is incorrect"
  const windowAvailable = typeof window !== "undefined"

  /* eslint-disable */
  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault()
    // @ts-ignore: PestportalsClient was delayed imported
    let auth = PestportalsClient.getAuthenticatedPortalLink(user, password)
    auth.then((response: any) => {
      if (response.success) {
        document.location.href = response.loginLink
      } else {
        windowAvailable && window.location.replace("/login?error=invalid")
      }
    })
  }
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.target.name === "acc_num"
      ? setUser(e.target.value)
      : setPassword(e.target.value)
  }
  const script = () => {
    !(window as any).jQuery &&
      appendScript({
        id: "jquery-3.6.0.min.js",
        scriptToAppend: "https://code.jquery.com/jquery-3.6.0.min.js",
        isAsync: false,
      })
    // @ts-ignore: PestportalsClient was delayed imported
    if ((window as any).jQuery && typeof PestportalsClient !== "object")
      appendScript({
        id: "pestportalsClient.js",
        scriptToAppend:
          "https://lmk.pestroutes.com/resources/js/lobster/pestportalsClient.js",
        isAsync: true,
      })
    else setTimeout(script, 200)
  }

  useEffect(() => {
    if (isRender) {
      script()
      fetch("https://lmk.pestroutes.com/lobster/getConnectedOffices")
        .then(response => response.json())
        .then(r => {
          setSubDomain(Object.keys(r.connectedOffices.dbOffices)[0])
        })
    } else {
      setIsRender(true)
    }
  }, [isRender])

  return (
    <section className="login-form-container">
      <div className="login-form">
        {heading && <div className="login-form__title">{heading}</div>}
        {subHeading && subHeading !== "<p><br></p>" ? (
          <div className="login-form__sub-title">{subHeading}</div>
        ) : (
          <div className="login-form__sub-title">
            Thank you for choosing {companyName}.
          </div>
        )}
        <div className="input-container">
          <p className="login-form__input-label">
            Account Number: <span>*</span>
          </p>
          <label className="login-form__input-container login-form__input-container--account">
            <input
              className="input"
              type="text"
              name="acc_num"
              value={user}
              onChange={handleChange}
            />
            <div className="input__icon">
              <FaUser />
            </div>
          </label>
          <p className="login-form__input-label">
            Password: <span>*</span>
          </p>
          <label className="login-form__input-container">
            <input
              className="input"
              type="password"
              name="pass"
              value={password}
              onChange={handleChange}
            />
            <div className="input__icon">
              <FaLock />
            </div>
          </label>
        </div>
        <p className="login-form__recover-password">
          Forgot your password?{" "}
          <ExternalLink
            href={`https://${subDomain}.pestportals.com/forgotPassword`}
          >
            Get your login info
          </ExternalLink>
        </p>
        <span className="login-form__error-message">
          <p>
            {windowAvailable &&
              window.location.href.includes("?error=invalid") &&
              loginMessage}
          </p>
        </span>
        <button
          role="button"
          className="button--solid-primary login-form__btn"
          onClick={handleSubmit}
        >
          {" "}
          Login{" "}
        </button>
      </div>
    </section>
  )
}
